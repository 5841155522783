.container {
  .inputClasses {
    margin-bottom: 0rem;
  }

  .inputInputClasses {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }

  .inputLabelClasses {
    transform: translate(0.8rem, 0.4rem);
  }

  .inputErrorClasses {
    min-height: 0;
  }

  .dropdownClasses {
    margin-top: 0rem;
  }
}
