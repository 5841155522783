.container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .button {
    min-width: 3.25rem;
    height: 2rem;
    padding: 0;
    margin: 0.1rem;
  }
}
