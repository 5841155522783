@import '../../../assets/scss/variables.scss';

$fill: #666;

.grid {
  line {
    stroke: $fill;
    stroke-opacity: 0.5;
    stroke-width: 1.5;
  }
}

.axis line {
  stroke: $fill;
}

.label {
  fill: $white;
  font-size: 1rem;
  text-anchor: middle;

  &.bottom {
    transform: translateY(0.6rem);
  }
}

.tick {
  fill: $fill;
  font-size: 0.8rem;
  text-anchor: middle;

  line {
    fill: $fill;
    stroke: $fill;
  }

  text {
    fill: $fill;
    font-size: 0.8rem;
    text-anchor: middle;

    &.left {
      text-anchor: end;
      vertical-align: middle;
    }
  }
}
