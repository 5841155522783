@import '../../../assets/scss/variables.scss';

.tooltipLabel {
  display: inline-flex;
  align-items: center;
  background-color: #00000087;
  border: 1px solid $white;
  transform: translateX(-50%);
  z-index: 1;

  div {
    padding: 0.3rem;
  }

  .xLabel {
    padding: 0.3rem;
    font-weight: 500;
  }

  .yLabel {
    display: flex;
    align-items: center;
    padding: 0.1rem 0.3rem;
  }

  .indicator {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.5rem;
  }
}
