a {
  color: #ffffff;
}
a:hover {
  color: #ffffff;
}
a.nav-link.active {
  color: #ffffff !important;
  background-color: transparent !important;
}
