/* timing */
table.timing td {
  padding: 0 !important;
}
/* lap/sector flags */
td.PB {
  color: lime;
}
td.SB {
  color: fuchsia;
}
td.OLD {
  color: yellow;
}
td.NEW {
  color: white;
}
td.position {
  color: turquoise;
}
