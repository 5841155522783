@import '../../../assets/scss/variables.scss';
@import '~web-common/src/components/button/button.module.scss';

$btn-templates: (
  'primary': $primary,
);

@each $btn_name, $color_value in $btn-templates {
  @include make-button($btn_name, $color_value);
  @include make-outline-button($btn_name, $color_value);
}
