$primary: #00b470;
$secondary: #6c757d;
$secondary-selected: #575e66;
$background: #16171b;
$foreground: #202125;
$font-color: #ffffff;
$black: #000000;
$white: #ffffff;

/* Font */
$font-size-default: 1rem;

/* Shadows */
$shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !default;
$soft-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04), 0 2px 10px 0 rgba(0, 0, 0, 0.04) !default;
$dialog-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.212) !default;
