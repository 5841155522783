@import '../../assets/scss/variables.scss';

$modal-box-shadow: 1px 1px 3px 0px #2b2b2b;
$modal-width: 60rem;
$modal-height: $modal-width * 0.5625;
$modal-border-radius: 0.5rem;

:global {
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 100ms ease-in-out;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
}

.modal {
  width: $modal-width;
  height: $modal-height;
  background: $background;
  box-shadow: $modal-box-shadow;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  overflow: visible;
  outline: none;
  top: 50%;
  left: 50%;

  :global {
    .plyr {
      border-radius: $modal-border-radius;
    }
  }

  .button {
    position: absolute;
    color: $white;
    right: -0.75rem;
    top: -0.75rem;
    z-index: 1;
  }
}
