@import '../../../assets/scss/variables.scss';

.btnGroup {
  display: flex;
  align-self: center;
  border-radius: 0.25rem;
  box-shadow: $shadow;
  border: 1px solid var(--border);

  .button {
    height: 2.2rem;
    margin: 0 !important;
    box-shadow: none !important;
    padding: 0.42rem 1.2rem !important;
    background: transparent;

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }
    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .active {
    background-color: #575e66;
  }
}
