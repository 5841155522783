@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800|Raleway&display=swap');
@import './assets/scss/variables.scss';

html,
body,
#root,
.App {
  height: 100%;
  width: 100%;
  color: $font-color;
  font-size: $font-size-default;
  margin: 0;
}
