.card {
  background-color: transparent;
}

.buttonContainer {
  display: flex;
  justify-content: center;

  .button {
    margin: 0 !important;
    padding: 0.5rem 1.2rem !important;
  }
}

.driverSelector {
  padding-bottom: 1rem;
}
