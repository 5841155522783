@import '../../../assets/scss/variables.scss';

.button {
  width: 100%;
  height: 2.5rem;
  margin: 0;
  font-size: 1rem !important;

  &::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
}

.active {
  background-color: $primary;
}
.dropdownMenu {
  min-width: 100%;
  text-align: center;
}
