@import '../../assets/scss/variables.scss';

.parentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: $dialog-shadow;
  background: var(--background-1);
}

.childContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.heading {
  display: flex;
  align-items: center;
  margin-left: 3rem;
  margin-right: 3rem;
}

.title {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.lapTimeBtnGroup {
  margin-left: auto;
  margin-right: 0.25rem;
}

.driverSelector {
  max-width: 19rem;
}

.chart {
  height: 20rem;
}
