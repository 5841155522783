@import '../../../assets/scss/variables.scss';

$background: #343a40;

.dot {
  fill: $background;
  stroke-width: 1px;
}

.linePath {
  stroke-width: 2.5px;
  stroke-opacity: 0.8;
}
