/* timing */
.timing td {
  padding: 0 !important;
  vertical-align: middle;
}
/* lap/sector flags */
.PB {
  color: lime;
}
.SB {
  color: fuchsia;
}
/* tyres */
.IN {
  color: lime;
}
.IU {
  color: lime;
}
.SN {
  color: red;
}
.SU {
  color: red;
}
.MN {
  color: yellow;
}
.MU {
  color: yellow;
}
.HN {
  color: white;
}
.HU {
  color: white;
}

.speedtrap {
  color: turquoise;
}

.button {
  margin: 0.1rem auto 0.1rem auto !important;
  padding: 0.4rem 1rem !important;
}

.driverSelector {
  padding-bottom: 1rem;
}
