.parentContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0.5rem;
  background: var(--background-1);
  box-shadow: 0px 0px 3px 1px #00000036;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.childContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.heading {
  display: flex;
  align-items: center;
  height: 2.5rem;
  margin-left: 3rem;
  margin-right: 3rem;
}

.title {
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.driverAutoCompleteContainer {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;

  .driverAutoComplete {
    max-width: 10rem;
  }
}

.chart {
  height: 23rem;
}
