.parentContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.childContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 8rem;
  padding: 3rem;
}

.heading {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2rem;

  .logo {
    margin-bottom: 0.75rem;
  }
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2rem;
  width: 100%;
}

.input {
  margin-bottom: 0rem;
}

.loginBtn {
  width: 99%;
  margin: 1px !important;
}

.copyright {
  margin-top: 1rem;
  color: #6c757d;
}
